import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Lincoln from 'react-lincoln'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import Select from 'react-select';

const deprecatedLayers = ['NewRelicPython27', 'NewRelicPython36', 'NewRelicPython37', 'NewRelicNodeJS810', 'NewRelicNodeJS10X', 'NewRelicNodeJS12X', 'NewRelicNodeJS12XARM64', 'NewRelicNodeJS14X', 'NewRelicNodeJS14XARM64']

class Docs extends Component {
    render() {
      return(
        <Lincoln definitionUrl='LayerAPI-prod-oas30.yaml' />
      );
    }
}

function Layer(props) {
    return(
        <tr className="layer">
            <td className="layer-name">{props.LayerName}</td>
            <td className="layer-arn">{props.LayerArn}</td>
            <td>
                <CopyToClipboard text={props.LayerArn}>
                  <button className="copy-clipboard">Copy</button>
                </CopyToClipboard>
            </td>
            <td className="layer-description">{props.LayerDescription}</td>
        </tr>)
}

class LayerList extends Component {
  constructor (props) {
    super(props);
    this.state = { layers: [] }
  }

  getLayers() {
    const url = 'https://'+this.props.region+'.layers.newrelic-external.com/get-layers';
    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((layers) => {
        this.setState({layers: layers["Layers"]});
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount() {
    this.getLayers();
  }

  componentDidUpdate(prevProps) {
    if (this.props.region !== prevProps.region) {
      this.getLayers();
    }
  }

  render() {
    const layers = this.state.layers.map((layer) => {
        if (deprecatedLayers.includes(layer.LayerName)) {
            return null
        }
        return (<Layer key={layer.LayerArn} LayerArn={layer.LatestMatchingVersion.LayerVersionArn} LayerName={layer.LayerName} LayerDescription={layer.LatestMatchingVersion.Description}/>)
    })
    return(
        <table className="layer-list">
            <thead>
            <tr>
              <th>Layer Name</th>
              <th>New Relic Lambda Layer ARN</th>
              <th>Copy</th>
              <th>Description</th>
            </tr>
          </thead>
            <tbody>
                {layers}
            </tbody>
        </table>
    )
  }
}

class App extends Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
        layers: [],
        region: 'us-west-2',
        selected: { value: 'us-west-2', label: 'US West (Oregon)' }
    };
    this.options = [
      { value: 'us-east-1', label: 'us-east-1: US East (N. Virginia)' },
      { value: 'us-east-2', label: 'us-east-2: US East (Ohio)' },
      { value: 'us-west-1', label: 'us-west-1: US West (N. California)' },
      { value: 'us-west-2', label: 'us-west-2: US West (Oregon)' },
      { value: 'eu-west-1', label: 'eu-west-1: Ireland' },
      { value: 'eu-west-2', label: 'eu-west-2: London' },
      { value: 'eu-west-3', label: 'eu-west-3: Paris' },
      { value: 'eu-central-1', label: 'eu-central-1: Frankfurt' },
      { value: 'eu-central-2', label: 'eu-central-2: Zurich' },
      { value: 'eu-north-1', label: 'eu-north-1: Stockholm' },
      { value: 'eu-south-1', label: 'eu-south-1: Milan' },
      { value: 'eu-south-2', label: 'eu-south-2: Spain' },
      { value: 'me-central-1', label: 'me-central-1: Middle East (UAE)' },
      { value: 'me-south-1', label: 'me-south-1: Middle East (Bahrain)' },
      { value: 'sa-east-1', label: 'sa-east-1: São Paulo' },
      { value: 'af-south-1', label: 'af-south-1: Africa (Cape Town)' },
      { value: 'ap-south-1', label: 'ap-south-1: Asia Pacific (Mumbai)' },
      { value: 'ap-south-2', label: 'ap-south-2: Asia Pacific (Hyderabad)' },
      { value: 'ap-northeast-1', label: 'ap-northeast-1: Asia Pacific (Tokyo)' },
      { value: 'ap-northeast-2', label: 'ap-northeast-2: Asia Pacific (Seoul)' },
      { value: 'ap-northeast-3', label: 'ap-northeast-3: Asia Pacific (Osaka)' },
      { value: 'ap-southeast-1', label: 'ap-southeast-1: Asia Pacific (Singapore)' },
      { value: 'ap-southeast-2', label: 'ap-southeast-2: Asia Pacific (Sydney)' },
      { value: 'ap-southeast-3', label: 'ap-southeast-3: Asia Pacific (Jakarta)' },
      { value: 'ap-southeast-4', label: 'ap-southeast-4: Asia Pacific (Melbourne)' },
      { value: 'ca-central-1', label: 'ca-central-1: Canada (Central)' }
    ];
  }

  render() {
    const { region } = this.state;

    const that = this
    const handleChange = function (selectedOption) {
        console.log('Option selected:', selectedOption.value);
        that.setState({ region: selectedOption.value, selected: selectedOption });
        ReactDOM.render(
          <App />,
          document.getElementById('root')
        );
    }

    return (
      <>
      <div className="container">
        <h1>Layers for New Relic installation</h1>
        <p>Install or upgrade New Relic using these convenient layers!</p>
        <p>
          Most users will want to apply these layers automatically using the
          {" "}<a href="https://github.com/newrelic/newrelic-lambda-cli">New Relic Lambda CLI</a> or
          {" "}<a href="https://github.com/newrelic/serverless-newrelic-lambda-layers/">New Relic Serverless Plugin</a>.
        </p>
        <p>
          Our layer ARNs and layer API are published here for your convenience if manually applying
          layers to your functions or building custom automation tooling.
        </p>
        <div className="select-container">
        <Select
          value={this.state.selected}
          onChange={handleChange}
          options={this.options}
        />
        </div>
        <Tabs>
          <TabList>
            <Tab>Layers</Tab>
            <Tab>API</Tab>
          </TabList>

          <TabPanel>
            <LayerList layers={this.state.layers} region={region} />
          </TabPanel>
          <TabPanel>
            <Docs region={region} />
          </TabPanel>
        </Tabs>
        </div>
      </>
    );
  }
}

export default App;
